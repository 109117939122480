import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from '../../../common/components/runtime-context';
import { useI18n } from '../../../common/hooks/use-i18n';
import { getRelatedPosts } from '../../../common/selectors/post-selectors';
import {
  getIsSeeAllLinkEnabled,
  getIsWidgetTitleEnabled,
} from '../../selectors/related-posts-selectors';
import PostList from '../post-list';
import SeeAllLink from '../see-all-link/see-all-link';
import styles from './related-posts.scss';

const RelatedPosts: React.FC = () => {
  useI18n();
  const { t } = useTranslation();

  const { isMobile, isViewer } = useEnvironment();
  const relatedPosts = useSelector(getRelatedPosts);
  const isSeeAllLinkEnabled = useSelector(getIsSeeAllLinkEnabled);
  const isWidgetTitleEnabled = useSelector(getIsWidgetTitleEnabled);
  const showHeader = isSeeAllLinkEnabled || isWidgetTitleEnabled;
  const title = isWidgetTitleEnabled ? (
    <h2 className={styles.title}>{t('related-posts.title')}</h2>
  ) : (
    <div />
  );
  const seeAllLink = isSeeAllLinkEnabled ? <SeeAllLink /> : <div />;

  if (!relatedPosts.length && isViewer) {
    return null;
  }

  return (
    <div className={isMobile ? styles.mobile : undefined}>
      {showHeader ? (
        <div className={styles.header}>
          {title}
          {seeAllLink}
        </div>
      ) : null}
      <div className={styles.list}>
        <PostList />
      </div>
    </div>
  );
};

export default RelatedPosts;
