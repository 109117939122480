import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { SECTION_BLOG_PAGE } from '@wix/communities-blog-client-common';
import Link from '../../../common/components/link/internal-link';
import { connect } from '../../../common/components/runtime-context';
import { getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import { type AppState } from '../../../common/types';
import {
  getIsSeeAllLinkLayoutText,
  getIsSeeAllLinkUppercase,
} from '../../selectors/see-all-link-selectors';
import styles from './see-all-link.scss';

type Props = ReturnType<typeof mapRuntimeToProps>;

const SeeAllLink = ({ isTextLayout, isUppercase, isMobile }: Props) => {
  const { t } = useTranslation();

  return (
    <Link
      to="/"
      as="button"
      sectionId={SECTION_BLOG_PAGE}
      className={classNames([
        { [styles.mobile]: isMobile },
        isTextLayout ? styles.seeAllLinkText : styles.seeAllLinkButton,
      ])}
    >
      {isUppercase
        ? t('related-posts.see-all-link.label').toUpperCase()
        : t('related-posts.see-all-link.label')}
    </Link>
  );
};

const mapRuntimeToProps = (state: AppState) => ({
  isTextLayout: getIsSeeAllLinkLayoutText(state),
  isUppercase: getIsSeeAllLinkUppercase(state),
  isMobile: getIsMobile(state),
});

export default connect(mapRuntimeToProps)(SeeAllLink);
